/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { PageHeader, Card, Table, Tag, message, Button, Row, Col } from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

export default function TransactionsDetails({ history, match }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    transaction: {
      invoice: {
        user: {},
      },
    },
    licenses: [],
  });

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "transactions",
      breadcrumbName: "Transações",
    },
  ];

  const columns = [
    {
      title: "Plugin",
      dataIndex: "pluginName",
      key: "pluginName",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Porta",
      dataIndex: "port",
      key: "port",
    },
    {
      title: "Última atualização",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text === "PENDING" ? (
          <Tag color="geekblue">Aguardando pagamento</Tag>
        ) : text === "WAITING" ? (
          <Tag color="warning">Ainda não foi ativo</Tag>
        ) : text === "INTEGRATED" ? (
          <Tag color="green">Integrado</Tag>
        ) : (
          <Tag color="red">Suspenso</Tag>
        );
      },
    },
    {
      title: "Detalhes",
      dataIndex: "details",
      key: "details",
      render: (text, record) => (
        <Button onClick={() => history.push("/transactions/" + record.id)}>
          Detalhes
        </Button>
      ),
    },
  ];

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/transaction/" + match.params.id);
      setData(result.data);
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar as transações");
    }

    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [match]);

  return (
    <AppLayout page="10">
      <PageHeader
        className="site-page-header"
        title="Transações"
        breadcrumb={{ routes }}
        subTitle="Detalhes da transações"
      />
      <Card
        title="Detalhes da transação"
        className="mt-4"
        loading={loading}
        bordered={false}
      >
        <Row>
          <Col md={12}>
            <h5>Detalhes da Transação</h5>
            <p>
              <b>Código:</b> {data.transaction.code} <br />
              <b>Código interno:</b> {data.transaction.reference} <br />
              <b>Nome do Pagador:</b> {data.transaction.payerName} <br />
              <b>E-mail do Pagador:</b> {data.transaction.payerEmail} <br />
              <b>Forma de Pagamento:</b> {data.transaction.paymentMethod} <br />
              <b>Status da API:</b> {data.transaction.gatewayStatus} <br />
            </p>
          </Col>
          <Col md={12}>
            <h5>Detalhes da Fatura</h5>
            <p>
              <b>Valor da Fatura:</b> R$
              {parseFloat(data.transaction.invoice.subtotal).toLocaleString(
                "pt-BR",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}{" "}
              <br />
              <b>Moeda:</b> {data.transaction.invoice.currency} <br />
              <b>Desconto:</b> R$
              {parseFloat(data.transaction.invoice.discount).toLocaleString(
                "pt-BR",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}{" "}
              <br />
              <b>Cupom de Desconto:</b> {data.transaction.invoice.cupom} <br />
              <b>Status do pagamento:</b>{" "}
              {data.transaction.invoice.status === "PENDING"
                ? "Aguardando Pagamento"
                : data.transaction.invoice.status === "PAID"
                ? "Pago"
                : "Reembolsado"}{" "}
              <br />
            </p>
          </Col>
        </Row>
      </Card>
      <Card title="Plugins comprados" className="mt-4" bordered={false}>
        <Table
          dataSource={data.licenses}
          columns={columns}
          locale={{ emptyText: "Não houve licenças nessa transação" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
