import React, { useState } from "react";
import { Spinner } from "reactstrap";
import { message } from "antd";
import ReCAPTCHA from "react-google-recaptcha";

import AuthLayout from "../../layouts/AuthLayout";

import Styles from "../../components/Styles";
import { Card } from "./styles";

import api from "../../api";
import { setToken } from "../../guard";

export default function Login({ history }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const recaptchaRef = React.createRef();

  async function submit() {
    if (!username)
      return message.info('Informe seu username');

    if (!password)
      return message.info('Informe a senha')

    const response = await recaptchaRef.current.executeAsync();

    if (!response) return message.info('Captcha não resolvido')

    setLoading(true);

    api
      .post("/user/session", {
        username,
        password,
        response
      })
      .then(result => {
        const { user, token } = result.data;

        if (user.level !== "ADMINISTRATOR")
          return message.warning("Você não está autorizado");

        setToken(token);
        history.push("/");
      })
      .catch(err => {
        message.error(err.response.data.message)
      }).then(() => {
        setLoading(false);
      });
  }

  return (
    <AuthLayout>
      <Styles authPage />
      <Card>
        <h1>Autentifique-se</h1>
        <h5>Entre com seus dados de acesso</h5>
        <input
          placeholder="Informe seu usuário"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          placeholder="Informe sua senha"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={submit}>
          {loading ? <Spinner color="light" size="sm" /> : "Entrar na conta"}
        </button>
      </Card>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LdearEZAAAAAKktAO99pbj3B2ZN2aeScIlWoXhU"
        size="invisible"
        badge="bottomright"
        onErrored={() => console.log('Error')}
        onExpired={() => console.log('Expired')}
      />
    </AuthLayout>
  );
}
