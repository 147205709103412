/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { BsDownload } from "react-icons/bs";
import Linkify from "react-linkify";
import nl2br from "react-nl2br";
import {
  PageHeader,
  Card,
  Button,
  Row,
  Col,
  Input,
  Upload,
  Form,
  Tag,
  Select,
  message as messager,
} from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

import { getToken } from "../../guard";
import { Link } from "react-router-dom";

const { Option } = Select;

export default function TicketsOpen({ match }) {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("Carregando...");
  const [priority, setPriority] = useState("HIGH");
  const [data, setData] = useState({
    messages: [],
    user: {
      id: 1,
      name: "",
    },
    files: [],
  });

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "tickets",
      breadcrumbName: "Tickets",
    },
  ];

  async function close() {
    setLoading(true);

    try {
      await api.delete("/ticket/" + match.params.id);

      await fetchMessage();
    } catch (err) {
      messager.error(err.response.data.message);
    }

    setLoading(false);
  }

  async function submit() {
    setLoading(true);

    try {
      await api.put("/ticket/message/" + match.params.id, { message });

      setMessage("");
      await fetchMessage();
    } catch (err) {
      messager.error("Não foi possível responder o ticket");
    }

    setLoading(false);
  }

  async function update() {
    setLoading(true);

    try {
      await api.put("/ticket/" + match.params.id + "/update", {
        title,
        priority,
      });
      messager.success("Ticket atualizado com sucesso.");
    } catch (err) {
      messager.error("Não foi possível atualizar o ticket");
    }
    setLoading(false);
  }

  async function fetchTicketInfo() {
    setLoading(true);

    try {
      const result = await api.get("/ticket/" + match.params.id);

      if (result.data) {
        const data = result.data;
        setTitle(data.subject);
        setPriority(data.priority);
      }
    } catch (err) {
      alert("Ocorreu um erro ao buscar os dados do ticket");
    }
    setLoading(false);
  }

  async function fetchMessage(withLoading = true, scroller = true) {
    if (withLoading) {
      setLoading(true);
    }

    try {
      const result = await api.get("/ticket/" + match.params.id);

      if (result.data) {
        const data = result.data;
        setData(data);
      }

      if (scroller) {
        setTimeout(() => {
          scrollToBottom();
        }, 500);
      }
    } catch (err) {
      alert("Ocorreu um erro ao tentar buscar o ticket");
    }

    if (withLoading) {
      setLoading(false);
    }
  }

  function scrollToBottom() {
    var div = document.querySelector(".chat");

    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  }

  useEffect(() => {
    fetchMessage();
    fetchTicketInfo();

    var interval = setInterval(() => {
      var div = document.querySelector(".chat");

      if (!div) {
        clearInterval(interval);
      }

      fetchMessage(false, false);
    }, 5000);
  }, []);

  return (
    <AppLayout page="8">
      <PageHeader
        className="site-page-header"
        title="Tickets"
        breadcrumb={{ routes }}
        subTitle="Responda seus tickets"
      />
      <Row gutter={16}>
        <Col md={8}>
          <Card
            title="Detalhes do ticket"
            className="mt-4"
            bordered={false}
            loading={loading}
          >
            <p>
              <b>
                Título:{" "}
                <Input
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                  style={{
                    width: "80%",
                    border: "none",
                    padding: 0,
                    outline: "none",
                  }}
                />{" "}
              </b>
            </p>

            <p>
              <b>Usuário :</b>{" "}
              <Link to={`/clients/${data.user.id}`} target="_blank">
                {data.user.username}
              </Link>
            </p>
            <p>
              <b>Prioridade: </b>
              <Select value={priority} onChange={(value) => setPriority(value)}>
                <Option value="HIGH">Alta</Option>
                <Option value="MEDIUM">Média</Option>
                <Option value="LOW">Baixa</Option>
              </Select>
            </p>

            <p>
              <b>Status:</b> {data.status}
            </p>
            <p>
              <b>Última atualização:</b>{" "}
              {new Date(data.updatedAt).toLocaleString("pt-BR")}
            </p>
            <p>
              <b>Criado em:</b>{" "}
              {new Date(data.createdAt).toLocaleString("pt-BR")}
            </p>
            <Button
              className="w-100"
              type="primary"
              onClick={update}
              style={{ marginBottom: "20px" }}
            >
              Atualizar informações do ticket
            </Button>
            <Button className="w-100" type="danger" onClick={close}>
              Encerrar ticket
            </Button>
          </Card>
          <Card
            title="Arquivos anexados"
            className="mt-4"
            bordered={false}
            loading={loading}
          >
            <Upload.Dragger
              name="file"
              multiple={true}
              previewFile={true}
              method="put"
              locale={{
                uploadError: "Ocorreu um erro",
                uploading: "Enviando...",
              }}
              action={`https://api.atlasplugins.com/v1/ticket/${match.params.id}/files`}
              headers={{
                authorization: `Bearer ${getToken()}`,
              }}
            >
              <p className="ant-upload-text">
                Clique ou arraste os arquivos para upload
              </p>
              <p className="ant-upload-hint">
                Você pode arrastar um ou mais arquivos. Não é necessário
                responder o ticket, o upload é automático.
              </p>
            </Upload.Dragger>
            <br />
            {data.files.map((file) => (
              <Tag
                onClick={() =>
                  window.open(
                    `https://api.atlasplugins.com/v1/file/download/${file.source}`,
                    "_blank"
                  )
                }
                style={{
                  display: "block",
                  width: "100%",
                  marginBottom: "5px",
                  padding: "10px",
                  cursor: "pointer",
                }}
              >
                {file.originalName} upado em{" "}
                {new Date(file.createdAt).toLocaleString("pt-BR")}{" "}
                <BsDownload />
              </Tag>
            ))}
          </Card>
        </Col>
        <Col md={16}>
          <Card
            title={`Chat - ${title}`}
            className="mt-4"
            bordered={false}
            loading={loading}
          >
            <div className="chat">
              {data.messages.map((el) => (
                <div
                  className={`chat-message ${
                    el.author !== "OWNER" && "chat-me"
                  }`}
                >
                  <div className="chat-message-body">
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={decoratedHref}
                          key={key}
                          style={{ color: "#fff", textDecoration: "underline" }}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      {nl2br(el.message)}
                    </Linkify>
                  </div>
                  <div className="chat-message-time">
                    {new Date(el.createdAt).toLocaleString("pt-br")}
                    {el.senderName && (
                      <>
                        <br />
                        por {el.senderName}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div>
              <Form.Item>
                <Input.TextArea
                  rows={4}
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
              </Form.Item>
              <Form.Item>
                <Button onClick={submit} loading={loading} type="primary">
                  Responder
                </Button>
              </Form.Item>
            </div>
          </Card>
        </Col>
      </Row>
    </AppLayout>
  );
}
