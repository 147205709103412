/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Card,
  Button,
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  Upload,
} from "antd";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";
import { getToken } from "../../guard";

export default function PluginsEdit({ match }) {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [editorStateDesc, setEditorStateDesc] = useState(
    EditorState.createEmpty()
  );
  const [editorStateCmd, setEditorStateCmd] = useState(
    EditorState.createEmpty()
  );
  const [editorStatePerms, setEditorStatePerms] = useState(
    EditorState.createEmpty()
  );

  const [form] = Form.useForm();

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "plugins",
      breadcrumbName: "Plugins",
    },
  ];

  async function submit({
    title,
    categoryId,
    price,
    promotional,
    version,
    image,
  }) {
    setLoading(true);

    const description = draftToHtml(
      convertToRaw(editorStateDesc.getCurrentContent())
    );
    const commands = draftToHtml(
      convertToRaw(editorStateCmd.getCurrentContent())
    );
    const permissions = draftToHtml(
      convertToRaw(editorStatePerms.getCurrentContent())
    );

    try {
      await api.patch(`/plugin/${match.params.id}`, {
        title,
        categoryId,
        price,
        promotional,
        version,
        image,
        description,
        commands,
        permissions,
      });

      await getData();
      message.success('Plugin atualizado com sucesso!')
    } catch (err) {
      message.error(err.response.data.message);
    }

    setLoading(false);
  }

  async function getData() {
    setLoading(true);

    try {
      const categoriesResult = await api.get("/category");
      const pluginsResult = await api.get(`/plugin/${match.params.id}`);

      setCategories(categoriesResult.data);
      setData(pluginsResult.data);

      form.setFieldsValue(pluginsResult.data);

      const blocksFromHtmlDesc = htmlToDraft(pluginsResult.data.description);
      const blocksFromHtmlCmd = htmlToDraft(pluginsResult.data.commands);
      const blocksFromHtmlPerms = htmlToDraft(pluginsResult.data.permissions);

      var { contentBlocks: cB1, entityMap: eM1 } = blocksFromHtmlDesc;
      var { contentBlocks: cB2, entityMap: eM2 } = blocksFromHtmlCmd;
      var { contentBlocks: cB3, entityMap: eM3 } = blocksFromHtmlPerms;

      var contentStateDesc = ContentState.createFromBlockArray(cB1, eM1);
      var contentStateCmd = ContentState.createFromBlockArray(cB2, eM2);
      var contentStatePerms = ContentState.createFromBlockArray(cB3, eM3);

      setEditorStateDesc(EditorState.createWithContent(contentStateDesc));
      setEditorStateCmd(EditorState.createWithContent(contentStateCmd));
      setEditorStatePerms(EditorState.createWithContent(contentStatePerms));
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar os dados");
    }

    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [match]);

  return (
    <AppLayout page="4">
      <PageHeader
        className="site-page-header"
        title="Plugins"
        breadcrumb={{ routes }}
        subTitle="Gerencie os plugins"
      />
      <Card
        title="Cadastrar plugin"
        bordered={false}
        className="mt-4 mb-4"
        loading={loading}
      >
        <Row gutter={16}>
          <Col md={18}>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              form={form}
              onFinish={submit}
            >
              <Form.Item
                name="title"
                label="Nome"
                rules={[{ required: true, message: "Informe o nome" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="categoryId"
                label="Categoria"
                rules={[{ required: true, message: "Informe a categoria" }]}
              >
                <Select placeholder="Selecione a categoria" allowClear>
                  {categories.map((category) => (
                    <Select.Option
                      key={`category-${category.id}`}
                      value={category.id}
                    >{`${category.name} - ${
                      category.type === "PAID" ? "Pago" : "Gratuito"
                    }`}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="price" label="Preço">
                <Input type="number" />
              </Form.Item>
              <Form.Item name="promotional" label="Preço promocional">
                <Input type="number" />
              </Form.Item>
              <Form.Item
                name="version"
                label="Versão"
                rules={[{ required: true, message: "Informe a versão" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="image"
                label="Imagem"
                rules={[
                  {
                    required: true,
                    message: "Informe o link direto da imagem",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Descrição">
                <Editor
                  editorState={editorStateDesc}
                  onEditorStateChange={setEditorStateDesc}
                />
              </Form.Item>
              <Form.Item label="Comandos">
                <Editor
                  editorState={editorStateCmd}
                  onEditorStateChange={setEditorStateCmd}
                />
              </Form.Item>
              <Form.Item label="Permissões">
                <Editor
                  editorState={editorStatePerms}
                  onEditorStateChange={setEditorStatePerms}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Salvar
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col md={6}>
            <Upload
              name="jar"
              method="put"
              action={`https://api.atlasplugins.com/v1/plugin/${match.params.id}/jar`}
              headers={{
                authorization: `Bearer ${getToken()}`,
              }}
            >
              <Button style={{ width: "100%" }}>
                Clique ou arraste o plugin
              </Button>
            </Upload>
            <br/>
            <Upload
              name="jar"
              method="put"
              action={`https://api.atlasplugins.com/v1/plugin/${match.params.id}/jarapi`}
              headers={{
                authorization: `Bearer ${getToken()}`,
              }}
            >
              <Button  style={{ width: "100%", marginLeft: "10px" }}>Atualizar JAR API</Button>
            </Upload>
          </Col>
        </Row>
      </Card>
    </AppLayout>
  );
}
