import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Card,
  Table,
  Button,
  Tag,
  Form,
  Input,
  Select,
  DatePicker,
  message,
} from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

export default function Discounts() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [form] = Form.useForm();

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "discounts",
      breadcrumbName: "Descontos",
    },
  ];

  const columns = [
    {
      title: "Cupom",
      dataIndex: "cupom",
      key: "cupom",
    },
    {
      title: "Desconto",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Forma de Desconto",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return text === "PERCENTAGE" ? "Porcentagem" : "Valor";
      },
    },
    {
      title: "Utilização máxima",
      dataIndex: "maxUsage",
      key: "maxUsage",
      render: (text) => {
        return text ? text : "Não definido";
      },
    },
    {
      title: "Expira em",
      dataIndex: "expireAt",
      key: "expireAt",
      render: (text) => {
        return text ? new Date(text).toLocaleDateString('pt-BR') : "Não definido";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text === "AVAILABLE" ? (
          <Tag color="success">Disponível</Tag>
        ) : (
          <Tag color="red">Indisponível</Tag>
        );
      },
    },
    {
      title: "Ação",
      dataIndex: "actions",
      key: "actions",
      render: (text, key) => (
        <Button type="danger" size="small" onClick={() => remove(key.id)}>
          Deletar
        </Button>
      ),
    },
  ];

  async function submit(data) {
    setLoading(true);

    try {
      await api.post("/discount", data);

      await getData();
      form.resetFields();
    } catch (err) {
      message.error(err.response.data.message);
    }

    setLoading(false);
  }

  async function remove(id) {
    setLoading(true);

    try {
      await api.delete("/discount/" + id);

      await getData();
    } catch (err) {
      message.error(err.response.data.message);
    }

    setLoading(false);
  }

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/discount");

      setData(
        result.data.map((e) => {
          return {
            key: e.id,
            ...e,
          };
        })
      );
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar as categorias");
    }

    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <AppLayout page="6">
      <PageHeader
        className="site-page-header"
        title="Descontos"
        breadcrumb={{ routes }}
        subTitle="Gerencie os descontos"
      />
      <Card title="Cadastrar novo desconto" bordered={false} className="mt-4 mb-4">
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={submit}
        >
          <Form.Item
            name="cupom"
            label="Cupom"
            rules={[{ required: true, message: "Informe o cupom" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="maxUsage"
            label="Utilização máxima"
            rules={[{ required: false, pattern: '[0-9]' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="expireAt"
            label="Expira em"
            rules={[{ required: false }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Desconto"
            rules={[{ required: true, pattern: '[0-9]' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label="Tipo"
            rules={[{ required: true, message: "Informe o tipo" }]}
          >
            <Select placeholder="Selecione o tipo do desconto" allowClear>
              <Select.Option value="PERCENTAGE">Porcentagem</Select.Option>
              <Select.Option value="AMOUNT">Valor</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Cadastrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card title="Descontos cadastrados" bordered={false}>
        <Table
          dataSource={data}
          columns={columns}
          locale={{ emptyText: "Não há descontos cadastrados" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
