import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.min.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./assets/styles/css/reset.css";

import PrivateRoute from "./components/PrivateRoute";

import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Clients from "./pages/Clients";
import ClientsDetails from "./pages/Clients/details";
import Categories from "./pages/Categories";
import Plugins from "./pages/Plugins";
import PluginsEdit from "./pages/Plugins/edit";
import Updates from "./pages/Updates";
import Discounts from "./pages/Discounts";
import Licenses from "./pages/Licenses";
import Tickets from "./pages/Tickets";
import TicketsOpen from "./pages/Tickets/open";
import Stats from "./pages/Stats";
import Demands from "./pages/Demands";
import Partners from "./pages/Partners";
import Transactions from "./pages/Transactions";
import TransactionsDetails from "./pages/Transactions/details";
import License from "./pages/License";
import ErrorReporting from "./pages/ErrorReporting";
import ErrorReportingDetails from "./pages/ErrorReportingDetails";

export default function Routes() {
  return (
    <HashRouter basename="/">
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/clients" component={Clients} />
        <PrivateRoute exact path="/clients/:id" component={ClientsDetails} />
        <PrivateRoute exact path="/categories" component={Categories} />
        <PrivateRoute exact path="/plugins/:id" component={PluginsEdit} />
        <PrivateRoute exact path="/plugins" component={Plugins} />
        <PrivateRoute exact path="/updates" component={Updates} />
        <PrivateRoute exact path="/discounts" component={Discounts} />
        <PrivateRoute exact path="/licenses" component={Licenses} />
        <PrivateRoute exact path="/tickets/:id" component={TicketsOpen} />
        <PrivateRoute exact path="/tickets" component={Tickets} />
        <PrivateRoute exact path="/stats" component={Stats} />
        <PrivateRoute exact path="/demands" component={Demands} />
        <PrivateRoute exact path="/partners" component={Partners} />
        <PrivateRoute exact path="/transactions" component={Transactions} />
        <PrivateRoute exact path="/atlaslicense" component={License} />
        <PrivateRoute exact path="/errors" component={ErrorReporting} />
        <PrivateRoute
          exact
          path="/errors/:id"
          component={ErrorReportingDetails}
        />
        <PrivateRoute
          exact
          path="/transactions/:id"
          component={TransactionsDetails}
        />
        <Route exact path="/login" component={Login} />
        <Route path="*" component={NotFound} />
      </Switch>
    </HashRouter>
  );
}
