import React, { useState, useEffect } from "react";
import { PageHeader, Card, Table, Tag, Input, message, Button } from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

export default function Licenses() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("")

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "licenses",
      breadcrumbName: "Licenças",
    },
  ];
  
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Usuário",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Porta",
      dataIndex: "port",
      key: "port",
    },
    {
      title: "Plugin",
      dataIndex: "plugin",
      key: "pluginName",
    },
    {
      title: "Integração",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text === 'INTEGRATED' ? (
          <Tag color="success">Integrado</Tag>
        ) : text === 'SUSPENSED' ? (
          <Tag color="orange">Suspenso</Tag>
        ) : (
          <Tag color="geekblue">Aguardando integração</Tag>
        );
      },
    },
    {
      title: "Deletar",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => 
        <Button type="danger" onClick={() => remove(record.id)}>Deletar</Button>,
    }
  ];

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/license");

      setData(
        result.data.map((e) => {
          return {
            key: e.id,
            ...e,
            username: e.user.username,
            plugin: e.plugin.title,
            ip: `${e.ip}:${e.port}`
          };
        })
      );
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar as licenças");
    }

    setLoading(false);
  }

  function filterData() {
    return data.filter(res => {
      return (JSON.stringify(res).toLocaleLowerCase()).match(search.toLocaleLowerCase());
    });
  }

  async function remove(id) {
    setLoading(true)

    try {
      await api.delete('/license/' + id)
      message.success('Licença deletada')

      getData()
    } catch (err) {
      message.error('Não foi possível deletar')
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AppLayout page="7">
      <PageHeader
        className="site-page-header"
        title="Licenças"
        breadcrumb={{ routes }}
        subTitle="Gerencie as licenças"
      />
      <Card title="Licenças" className="mt-4" bordered={false}>
        <Input block placeholder="Pesquise pelo usuário, IP ou plugin" value={search} onChange={e => setSearch(e.target.value)} size="large" />
        <br /><br />
        <Table
          dataSource={search.length > 0 ? filterData() : data}
          columns={columns}
          locale={{ emptyText: "Não há licenças habilitadas" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
