import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Row,
  Col,
  Card,
  Table,
  Button,
  Input,
  Select,
  message,
  Tag,
} from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

export default function Stats() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    total: 0,
    mercadopago: 0,
    paypal: 0,
    stats: [],
  });

  const [amount, setAmount] = useState(0);
  const [gateway, setGateway] = useState("MERCADOPAGO");
  const [type, setType] = useState("INPUT");

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "stats",
      breadcrumbName: "Relatórios",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Gateway",
      dataIndex: "gateway",
      key: "gateway",
      render: (text) => (text === "MERCADOPAGO" ? "Mercado Pago" : "PayPal"),
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (text) => (text === "INPUT" ? "Entrada" : "Saída"),
    },
    {
      title: "Data",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => {
        if (record.type === "INPUT") {
          return (
            <Tag color="green">
              + R$
              {parseFloat(text).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Tag>
          );
        } else {
          return (
            <Tag color="red">
              - R$
              {parseFloat(text).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Tag>
          );
        }
      },
    },
  ];

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/stats/resume");

      setAmount(0)
      setGateway('MERCADOPAGO')
      setType('INPUT')

      setData(result.data)
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar os dados");
    }

    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  async function submit() {
    if (amount === 0) return message.info('Informe o valor')

    setLoading(true)

    try {
      await api.post('/stats', { amount, gateway, type })
      await getData()
    } catch (err) {
      message.error('Ocorreu um erro ao tentar cadastrar seu registro')
    }

    setLoading(false)
  }

  return (
    <AppLayout page="9">
      <PageHeader
        className="site-page-header"
        title="Relatórios"
        breadcrumb={{ routes }}
        subTitle="Relatório de entrada e saída"
      />
      <br />
      <Row gutter={25}>
        <Col span={24} md={8}>
          <Card className="p-3" loading={loading}>
            <h5 className="m-0 font-weight-light text-uppercase text-muted">
              Total
            </h5>
            <h1 className="m-0">
              R$
              {parseFloat(data.total).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h1>
          </Card>
        </Col>
        <Col span={24} md={8}>
          <Card className="p-3" loading={loading}>
            <h5 className="m-0 font-weight-light text-uppercase text-muted">
              Mercado Pago
            </h5>
            <h1 className="m-0">
              R$
              {parseFloat(data.mercadopago).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h1>
          </Card>
        </Col>
        <Col span={24} md={8}>
          <Card className="p-3" loading={loading}>
            <h5 className="m-0 font-weight-light text-uppercase text-muted">
              PayPal
            </h5>
            <h1 className="m-0">
              R$
              {parseFloat(data.paypal).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h1>
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={25}>
        <Col span={24} md={16}>
          <Card title="Movimentações" bordered={false}>
            <Table
              dataSource={data.stats}
              columns={columns}
              locale={{ emptyText: "Não há registro" }}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={24} md={8}>
          <Card
            title="Entradas/saídas manuais"
            bordered={false}
            className="mb-4"
          >
            <Input
              type="number"
              placeholder="Valor"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              size="large"
              className="mb-3"
            />
            <Select
              placeholder="Selecione a gateway"
              value={gateway}
              onChange={setGateway}
              size="large"
              className="mr-3"
            >
              <Select.Option value="MERCADOPAGO">Mercado Pago</Select.Option>
              <Select.Option value="PAYPAL">PayPal</Select.Option>
            </Select>
            <Select
              placeholder="Selecione o tipo"
              value={type}
              onChange={setType}
              size="large"
            >
              <Select.Option value="INPUT">Entrada</Select.Option>
              <Select.Option value="OUTPUT">Saída</Select.Option>
            </Select>
            <br />
            <br />
            <Button size="large" type="primary" onClick={submit}>
              Cadastrar
            </Button>
          </Card>
        </Col>
      </Row>
    </AppLayout>
  );
}
