const AUTH_SESSION = "Auth@Token";

export function setToken(token) {
  return localStorage.setItem(AUTH_SESSION, token);
}

export function getToken() {
  return localStorage.getItem(AUTH_SESSION);
}

export function isLogged() {
  return localStorage.getItem(AUTH_SESSION) !== null;
}

export function logout() {
  return localStorage.removeItem(AUTH_SESSION);
}
