import React from 'react';

import { Page } from './styles'

export default function NotFound() {
  return (
    <Page>
      <h1>404</h1>
      <h3>Página não encontrada</h3>
    </Page>
  )
}