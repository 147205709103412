import { createGlobalStyle } from "styled-components";

const Styles = createGlobalStyle`
  body {
    background: ${({ authPage }) => (authPage ? "#7158e2" : "#fff")};
  }

  button {
    border: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

export default Styles;
