import React, { useState, useEffect } from "react";
import { PageHeader, Card, Table, Input, message, Button } from "antd";

import PriorityTag from "../../components/PriorityTag";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

export default function Tickets({ history }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "tickets",
      breadcrumbName: "Tickets",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Usuário",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Título",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Prioridade",
      dataIndex: "priority",
      key: "priority",
      render: (text) => {
        return <PriorityTag priority={text} />;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text === "OPENED"
          ? "NOVO"
          : text === "WAITING"
          ? "Aguardando resposta"
          : "Fechado";
      },
    },
    {
      title: "Última atualização",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => {
        return <span>{new Date(text).toLocaleString("pt-br")}</span>;
      },
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (text, keys) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="primary"
              onClick={() =>
                window.open(
                  `https://admin.atlasplugins.com/#/tickets/${keys.id}`
                )
              }
            >
              Abrir
            </Button>
            <Button
              type="danger"
              className="ml-3"
              onClick={() => remove(keys.id)}
            >
              Encerrar
            </Button>
          </div>
        );
      },
    },
  ];

  async function remove(id) {
    setLoading(true);

    try {
      await api.delete("/ticket/" + id);

      await getData();
    } catch (err) {
      message.error(err.response.data.message);
    }

    setLoading(false);
  }

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/ticket");

      setData(
        result.data.map((e) => {
          return {
            key: e.id,
            username: e.user.username,
            ...e,
          };
        })
      );
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar os tickets");
    }

    setLoading(false);
  }

  function filterData() {
    return data.filter((res) => {
      return JSON.stringify(res)
        .toLocaleLowerCase()
        .match(search.toLocaleLowerCase());
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <AppLayout page="8">
      <PageHeader
        className="site-page-header"
        title="Tickets"
        breadcrumb={{ routes }}
        subTitle="Responda seus tickets"
      />
      <Card
        title="Tickets"
        className="mt-4"
        style={{ overflowX: "auto" }}
        bordered={false}
      >
        <Input
          block
          placeholder="Pesquisar..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size="large"
        />
        <br />
        <br />
        <Table
          dataSource={search.length > 0 ? filterData() : data}
          columns={columns}
          locale={{ emptyText: "Não há tickets abertos" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
