import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import nl2br from "react-nl2br";

import api from "../../api";

function DemandModal({ data, onRemove }) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  async function remove(event) {
    setLoading(true);

    try {
      await api.delete(`/demand/${data.id}`);

      message.success("Pedido deletado!");
      setVisible(false);

      onRemove();
    } catch (err) {
      message.error("Ocorreu um erro ao tentar excluir o pedido");
    }

    setLoading(false);
  }

  return (
    <>
      <Modal
        title="Detalhes da encomenda"
        maskClosable={false}
        closable={false}
        visible={visible}
        okText="Fechar"
        cancelText="Deletar"
        cancelButtonProps={{ type: "danger", loading }}
        onCancel={remove}
        onOk={() => setVisible(false)}
      >
        {nl2br(data.description)}
      </Modal>
      <Button onClick={() => setVisible(true)}>Abrir detalhes</Button>
    </>
  );
}

export default DemandModal;
