import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Card,
  Table,
  Button,
  Form,
  Input,
  Select,
  Upload,
  message,
} from "antd";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";
import { getToken } from "../../guard";

export default function Updates() {
  const [loading, setLoading] = useState(true);
  const [plugins, setPlugins] = useState([]);
  const [data, setData] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [pluginId, setPluginId] = useState(false);

  const [form] = Form.useForm();

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "updates",
      breadcrumbName: "Atualizações",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Plugin",
      dataIndex: "pluginName",
      key: "pluginName",
    },
    {
      title: "Versão",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Data de Publicação",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Ação",
      dataIndex: "actions",
      key: "actions",
      render: (text, key) => (
        <span>
          <Button
            className="mr-3"
            type="danger"
            size="small"
            onClick={() => remove(key.plugin.id, key.id)}
          >
            Deletar
          </Button>
        </span>
      ),
    },
  ];

  async function submit({ pluginId, version }) {
    setLoading(true);

    const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    try {
      await api.put(`/plugin/${pluginId}/update`, {
        version,
        body,
      });

      await getData();

      form.resetFields();
    } catch (err) {
      message.error(err.response.data.message);
    }

    setLoading(false);
  }

  async function remove(pluginId, id) {
    setLoading(true);

    try {
      await api.delete("/plugin/" + pluginId + "/update/" + id);

      await getData();
    } catch (err) {
      message.error(err.response.data.message);
    }

    setLoading(false);
  }

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/plugin/update/list");
      const pluginsResult = await api.get("/plugin");

      setPlugins(pluginsResult.data);
      setData(
        result.data.map((e) => {
          return {
            key: e.id,
            pluginName: e.plugin.title,
            ...e,
          };
        })
      );
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar os dados");
    }

    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <AppLayout page="5">
      <PageHeader
        className="site-page-header"
        title="Atualizações"
        breadcrumb={{ routes }}
        subTitle="Gerencie as atualizações"
      />
      <Card
        title="Cadastrar atualização"
        bordered={false}
        className="mt-4 mb-4"
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={submit}
        >
          <Form.Item
            name="version"
            label="Versão"
            rules={[{ required: true, message: "Informe a versão" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="pluginId"
            label="Plugin"
            rules={[{ required: true, message: "Informe o plugin" }]}
          >
            <Select
              placeholder="Selecione o plugin"
              onChange={setPluginId}
              allowClear
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {plugins.map((plugin) => (
                <Select.Option key={`pl-${plugin.id}`} value={plugin.id}>
                  {plugin.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="description" label="Descrição">
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <div style={{ display: "flex"}}>
            <Button
              type="primary"
              htmlType="submit"
              className="mr-3"
              loading={loading}
            >
              Cadastrar
            </Button>
            {pluginId && (
              <>
              
              <Upload
                name="jar"
                method="put"
                action={`https://api.atlasplugins.com/v1/plugin/${pluginId}/jar`}
                headers={{
                  authorization: `Bearer ${getToken()}`,
                }}
              >
                <Button style={{ width: "100%" }}>Atualizar JAR</Button>
              </Upload>
              
              <Upload
              name="jar"
              method="put"
              action={`https://api.atlasplugins.com/v1/plugin/${pluginId}/jarapi`}
              headers={{
                authorization: `Bearer ${getToken()}`,
              }}
            >
              <Button  style={{ width: "100%", marginLeft: "10px" }}>Atualizar JAR API</Button>
            </Upload>
            
              </>
            )}
            </div>
          </Form.Item>
        </Form>
      </Card>
      <Card title="Atualizações cadastradas" bordered={false}>
        <Table
          dataSource={data}
          columns={columns}
          locale={{ emptyText: "Não há atualizações" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
