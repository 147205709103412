import React, { useState, useEffect } from "react";
import { PageHeader, Card, Table, Tag, Input, message, Button } from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

export default function Clients({ history }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("")

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "clients",
      breadcrumbName: "Clientes",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Usuário",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: (text) => (text === "" ? "Não definido" : text),
    },
    {
      title: "Discord",
      dataIndex: "discord",
      key: "discord",
      render: (text) => (!text ? "Não definido" : text),
    },
    {
      title: "Hierarquia",
      dataIndex: "level",
      key: "level",
      render: (text) => {
        return text === "CUSTOMER" ? (
          <Tag color="warning">Cliente</Tag>
        ) : text === "ADMINISTRATOR" ? (
          <Tag color="geekblue">Administrador</Tag>
        ) : text === "SUPPORT" ? (
          <Tag color="gold">Suporte</Tag>
        ) : (
          <Tag color="red">Suspendo</Tag>
        );
      },
    },
    {
      title: "Data de cadastro",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Ação",
      key: "action",
      render: (text, record) => {
        return (
          <>
            <Button
              type="primary"
              onClick={() => history.push(`/clients/${record.id}`)}
            >
              Visualizar
            </Button>
            <Button
              onClick={() => remove(record.id)}
              type="danger"
              className="ml-2"
            >
              Deletar
            </Button>
          </>
        );
      },
    },
  ];

  async function remove(id) {
    try {
      await api.delete(`/user/${id}`);

      await getData();
    } catch (err) {
      message.error("Não foi possível deletar esta conta no momento");
    }
  }

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/user");

      setData(
        result.data.map((e) => {
          return {
            key: e.id,
            ...e,
          };
        })
      );
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar os usuários");
    }

    setLoading(false);
  }

  function filterData() {
    return data.filter(res => {
      return (JSON.stringify(res).toLocaleLowerCase()).match(search.toLocaleLowerCase());
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <AppLayout page="2">
      <PageHeader
        className="site-page-header"
        title="Clientes"
        breadcrumb={{ routes }}
        subTitle="Gerencie os clientes"
      />
      <Card title="Lista de clientes" className="mt-4" bordered={false}>
        <Input block placeholder="Pesquise pelo usuário, IP ou plugin" value={search} onChange={e => setSearch(e.target.value)} size="large" />
        <br /><br />
        <Table
          dataSource={search.length > 0 ? filterData() : data}
          columns={columns}
          locale={{ emptyText: "Não há clientes cadastrados" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
