import "datejs";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Row, Col, Card, Table, message } from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

export default function Home() {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    tickets: 0,
    licenses: 0,
    clients: 0,
    sales: 0,
    transactions: [],
    graph: {
      pie: {
        labels: [],
        series: [],
      },
      area: {
        labels: [],
        series: [],
      },
    },
  });

  const columns = [
    {
      title: "Código de Transação",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Gateway",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Referência",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Nome do Pagador",
      dataIndex: "payerName",
      key: "payerName",
    },
    {
      title: "E-mail do Pagador",
      dataIndex: "payerEmail",
      key: "payerEmail",
    },
    {
      title: "Valor",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      render: (text) =>
        parseFloat(text).toLocaleString("pt-br", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text) => {
        return text === "PAID"
          ? "Pago"
          : text === "PENDING"
          ? "Pendente"
          : "Devolvido";
      },
    },
    {
      title: "Última atualização",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => {
        return <span>{new Date(text).toLocaleString("pt-br")}</span>;
      },
    },
  ];

  const options = {
    chart: {
      id: "basic-bar",
      foreColor: "#ccc",
      toolbar: {
        autoSelected: "pan",
        show: false,
      },
    },
    xaxis: {
      tickAmount: 6,
      categories: data.graph.area.labels.map((e) => {
        var split = e.split("-");
        return `${split[2]}/${split[1]}`;
      }),
    },
    stroke: {
      width: 2,
      curve: "straight",
    },
    colors: ["#7158e2"],
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      borderColor: "#fff",
      clipMarkers: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    animations: {
      enabled: true,
      easing: "easeout",
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.9,
        opacityTo: 0,
      },
    },
    markers: {
      size: 3,
      colors: ["#7158e2"],
      strokeColor: "#7158e2",
      strokeWidth: 1,
    },
    tooltip: {
      theme: "light",
    },
    yaxis: {
      show: false,
    },
  };

  const series = [
    {
      name: "Renda",
      data: data.graph.area.series,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/stats");

      setData(result.data);
    } catch (err) {
      message.error("Não foi possível buscar os dados");
    }

    setLoading(false);
  }

  return (
    <AppLayout page="1">
      <Row gutter={25}>
        <Col span={24} md={6}>
          <Card className="p-3" loading={loading}>
            <h5 className="m-0 font-weight-light text-uppercase text-muted">
              Clientes
            </h5>
            <h1 className="m-0">{data.clients}</h1>
          </Card>
        </Col>
        <Col span={24} md={6}>
          <Card className="p-3" loading={loading}>
            <h5 className="m-0 font-weight-light text-uppercase text-muted">
              Licenças ativas
            </h5>
            <h1 className="m-0">{data.licenses}</h1>
          </Card>
        </Col>
        <Col span={24} md={6}>
          <Card className="p-3" loading={loading}>
            <h5 className="m-0 font-weight-light text-uppercase text-muted">
              Tickets
            </h5>
            <h1 className="m-0">{data.tickets}</h1>
          </Card>
        </Col>
        <Col span={24} md={6}>
          <Card className="p-3" loading={loading}>
            <h5 className="m-0 font-weight-light text-uppercase text-muted">
              Vendas
            </h5>
            <h1 className="m-0">{data.sales}</h1>
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={25}>
        <Col span={24} md={16}>
          <Card title="Renda mensal">
            <Chart
              series={series}
              options={options}
              type="area"
              width="100%"
              height="280px"
            />
          </Card>
        </Col>
        <Col span={24} md={8}>
          <Card title="Cupons utilizados">
            <div className="pt-3" />
            <Chart
              width="100%"
              height="280px"
              options={{
                chart: {
                  width: "100%",
                  type: "pie",
                },
                labels: data.graph.pie.labels,
                theme: {
                  monochrome: {
                    enabled: true,
                    color: "#7158e2",
                  },
                },
                plotOptions: {
                  pie: {
                    dataLabels: {
                      offset: -5,
                    },
                  },
                },
                dataLabels: {
                  formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex];
                    return [name, val.toFixed(1) + "%"];
                  },
                },
                legend: {
                  show: false,
                },
              }}
              type="pie"
              series={data.graph.pie.series}
            />
          </Card>
        </Col>
      </Row>
      <br />
      <Card title="Transações recentes">
        <Table
          dataSource={data.transactions}
          columns={columns}
          locale={{ emptyText: "Não há transações recentes" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
