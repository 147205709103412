import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Card,
  Table,
  Button,
  Tag,
  Form,
  Input,
  Select,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

export default function Plugins() {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("")
  const [editorStateDesc, setEditorStateDesc] = useState(
    EditorState.createEmpty()
  );
  const [editorStateCmd, setEditorStateCmd] = useState(
    EditorState.createEmpty()
  );
  const [editorStatePerms, setEditorStatePerms] = useState(
    EditorState.createEmpty()
  );

  const [form] = Form.useForm();

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "plugins",
      breadcrumbName: "Plugins",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Versão",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Categoria",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Monetização",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return text === "FREE" ? "Gratuito" : "Pago";
      },
    },
    {
      title: "Downloads",
      dataIndex: "downloads",
      key: "downloads",
    },
    {
      title: "Arquivo",
      dataIndex: "file",
      key: "file",
      render: (text) => {
        return text ? (
          <Tag color="purple">OK</Tag>
        ) : (
          <Tag color="warning">Não encontrado</Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text === "PUBLISHED" ? (
          <Tag color="success">Publicado</Tag>
        ) : (
          <Tag color="danger">Removido</Tag>
        );
      },
    },
    {
      title: "Ação",
      dataIndex: "actions",
      key: "actions",
      render: (text, key) => (
        <span>
          <Button
            className="mr-3"
            type="danger"
            size="small"
            onClick={() => remove(key.id)}
          >
            Deletar
          </Button>
          <Link to={`/plugins/${key.id}`}>Editar</Link>
        </span>
      ),
    },
  ];

  async function submit({ title, categoryId, price, version }) {
    setLoading(true);

    const description = draftToHtml(
      convertToRaw(editorStateDesc.getCurrentContent())
    );
    const commands = draftToHtml(
      convertToRaw(editorStateCmd.getCurrentContent())
    );
    const permissions = draftToHtml(
      convertToRaw(editorStatePerms.getCurrentContent())
    );

    try {
      await api.post("/plugin", {
        title,
        categoryId,
        price,
        version,
        image: "https://atlasplugins.com",
        description,
        commands,
        permissions,
      });

      await getData();

      form.resetFields();

      setEditorStateDesc(EditorState.createEmpty());
      setEditorStateCmd(EditorState.createEmpty());
      setEditorStatePerms(EditorState.createEmpty());
    } catch (err) {
      message.error(err.response.data.message);
    }

    setLoading(false);
  }

  async function remove(id) {
    setLoading(true);

    try {
      await api.delete("/plugin/" + id);

      await getData();
    } catch (err) {
      message.error(err.response.data.message);
    }

    setLoading(false);
  }

  async function getData() {
    setLoading(true);

    try {
      const categoriesResult = await api.get("/category");
      const pluginsResult = await api.get("/plugin");

      setCategories(categoriesResult.data);
      setData(
        pluginsResult.data.map((e) => {
          return {
            key: e.id,
            type: e.category.type,
            ...e,
            category: e.category.name,
          };
        })
      );
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar os dados");
    }

    setLoading(false);
  }

  function filterData() {
    return data.filter(res => {
      return (JSON.stringify(res).toLocaleLowerCase()).match(search.toLocaleLowerCase());
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <AppLayout page="4">
      <PageHeader
        className="site-page-header"
        title="Plugins"
        breadcrumb={{ routes }}
        subTitle="Gerencie os plugins"
      />
      <Card title="Cadastrar plugin" bordered={false} className="mt-4 mb-4">
        <Form
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 21 }}
          form={form}
          onFinish={submit}
        >
          <Form.Item
            name="title"
            label="Nome"
            rules={[{ required: true, message: "Informe o nome" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="categoryId"
            label="Categoria"
            rules={[{ required: true, message: "Informe a categoria" }]}
          >
            <Select placeholder="Selecione a categoria" allowClear>
              {categories.map((category) => (
                <Select.Option
                  key={`category-${category.id}`}
                  value={category.id}
                >{`${category.name} - ${
                  category.type === "PAID" ? "Pago" : "Gratuito"
                }`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="price" label="Preço">
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="version"
            label="Versão"
            rules={[{ required: true, message: "Informe a versão" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Descrição">
            <Editor
              editorState={editorStateDesc}
              onEditorStateChange={setEditorStateDesc}
            />
          </Form.Item>
          <Form.Item label="Comandos">
            <Editor
              editorState={editorStateCmd}
              onEditorStateChange={setEditorStateCmd}
            />
          </Form.Item>
          <Form.Item label="Permissões">
            <Editor
              editorState={editorStatePerms}
              onEditorStateChange={setEditorStatePerms}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Cadastrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card title="Plugins cadastrados" bordered={false}>
        <Input block placeholder="Pesquisar..." value={search} onChange={e => setSearch(e.target.value)} size="large" />
        <br /><br />
        <Table
          dataSource={search.length > 0 ? filterData() : data}
          columns={columns}
          locale={{ emptyText: "Não há plugins cadastrados" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
