import React, { useState } from "react";
import reqwest from "reqwest";
import { Editor } from "react-draft-wysiwyg";

import {
  PageHeader,
  Card,
  Input,
  message,
  Button,
  Select,
  Form,
  Upload,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";

import AppLayout from "../../layouts/AppLayout";

import { EditorState } from "draft-js";
import { getToken } from "../../guard";

export default function License() {
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [uploading, setUploading] = useState(false);

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "atlaslicense",
      breadcrumbName: "AtlasLicense",
    },
  ];

  const props = {
    accept: ".jar,application/java-archive",
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  async function handleForm({ title, version, stage }) {
    if (fileList.length === 0) {
      message.error(
        "É necessário enviar um arquivo JAR para a atualização do AtlasLicense."
      );
      return;
    }

    const body = editorState.getCurrentContent().getPlainText();
    const formData = new FormData();
    formData.append("jar", fileList[0]);
    formData.append("title", title);
    formData.append("stage", stage);
    formData.append("description", body);
    formData.append("version", version);

    setUploading(true);

    reqwest({
      url: "https://api.atlasplugins.com/v2/license/update",
      method: "put",
      processData: false,
      data: formData,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      success: () => {
        setFileList([]);
        setUploading(false);
        message.success("Atualização enviada com sucesso.");
      },
      error: () => {
        setUploading(false);
        message.error("Ocorreu um erro durante o processamento do formulário.");
      },
    });
  }

  return (
    <AppLayout page="14">
      <PageHeader
        className="site-page-header"
        title="AtlasLicense"
        breadcrumb={{ routes }}
        subTitle="Enviar atualizações para o AtlasLicense"
      />
      <Card title="Enviar atualização" bordered={false} className="mt-4 mb-4">
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={handleForm}
        >
          <Form.Item
            name="title"
            label="Título"
            rules={[
              {
                required: true,
                message: "Informe o título da atualização",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="version"
            label="Versão"
            rules={[
              {
                required: true,
                message: "Informe a nova versão do atlaslicense",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="stage"
            label="Estágio"
            rules={[
              { required: true, message: "Informe o estágio de atualização" },
            ]}
          >
            <Select placeholder="Estágio de atualização">
              <Select.Option key={`RELEASE`} value={"RELEASE"}>
                RELEASE
              </Select.Option>
              <Select.Option key={`ALPHA`} value={"ALPHA"}>
                ALPHA
              </Select.Option>
              <Select.Option key={`BETA`} value={"BETA"}>
                BETA
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="description"
            label="Descrição"
            rules={[
              {
                required: true,
                message: "É necessário informar uma descrição da atualização.",
              },
            ]}
          >
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            {fileList.length === 0 ? (
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>
                  Selecionar Arquivo JAR
                </Button>
              </Upload>
            ) : (
              <Upload {...props}></Upload>
            )}
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="mr-3"
                loading={uploading}
              >
                Atualizar
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AppLayout>
  );
}
