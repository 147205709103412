import React from "react";
import { Layout, Menu } from "antd";
import { Link, useHistory } from "react-router-dom";

import { logout } from "../../guard";

export default function AppLayout({ children, page }) {
  const history = useHistory();

  const { Header, Content, Footer, Sider } = Layout;

  return (
    <Layout>
      <Sider breakpoint="lg" collapsedWidth="0">
        <div
          className="logo text-center font-weight-bold mb-4 text-white"
          style={{ fontSize: 20, background: "transparent" }}
        >
          <img
            src={require("../../assets/images/logo-white.png")}
            alt="AtlasPlugins"
            width="50"
          />
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[page]}>
          <Menu.Item key="1">
            <span className="nav-text">Dashboard</span>
            <Link to="/" />
          </Menu.Item>
          <Menu.Item key="2">
            <span className="nav-text">Clientes</span>
            <Link to="/clients" />
          </Menu.Item>
          <Menu.Item key="3">
            <span className="nav-text">Categorias</span>
            <Link to="/categories" />
          </Menu.Item>
          <Menu.Item key="4">
            <span className="nav-text">Plugins</span>
            <Link to="/plugins" />
          </Menu.Item>
          <Menu.Item key="5">
            <span className="nav-text">Atualização</span>
            <Link to="/updates" />
          </Menu.Item>
          <Menu.Item key="6">
            <span className="nav-text">Descontos</span>
            <Link to="/discounts" />
          </Menu.Item>
          <Menu.Item key="7">
            <span className="nav-text">Licenças</span>
            <Link to="/licenses" />
          </Menu.Item>
          <Menu.Item key="8">
            <span className="nav-text">Tickets</span>
            <Link to="/tickets" />
          </Menu.Item>
          <Menu.Item key="9">
            <span className="nav-text">Relatórios</span>
            <Link to="/stats" />
          </Menu.Item>
          <Menu.Item key="10">
            <span className="nav-text">Transações</span>
            <Link to="/transactions" />
          </Menu.Item>
          <Menu.Item key="11">
            <span className="nav-text">Parceiros</span>
            <Link to="/partners" />
          </Menu.Item>
          <Menu.Item key="12">
            <span className="nav-text">Encomendas</span>
            <Link to="/demands" />
          </Menu.Item>
          <Menu.Item key="14">
            <span className="nav-text">AtlasLicense</span>
            <Link to="/atlaslicense" />
          </Menu.Item>
          <Menu.Item key="15">
            <span className="nav-text">Erros reportados</span>
            <Link to="/errors" />
          </Menu.Item>
          <Menu.Item
            key="13"
            onClick={() => {
              logout();
              history.push("/");
            }}
          >
            <span className="nav-text">Sair</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{ padding: 0 }}
        />
        <Content>
          <div style={{ padding: 24, minHeight: "84vh" }}>{children}</div>
        </Content>
        <Footer style={{ textAlign: "center" }}>Kodesky &copy;</Footer>
      </Layout>
    </Layout>
  );
}
