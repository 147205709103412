import axios from "axios";
import { getToken, logout } from "../guard";

// https://api.atlasplugins.com/v1

const http = axios.create({
  baseURL: "https://api.atlasplugins.com/v2",
});

http.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    var {
      status,
      data: { message },
    } = error.response;

    if (status === 401 && message === "User not found") {
      logout();
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export default http;
