import React, { useState, useEffect } from "react";
import { PageHeader, Card, Table, message } from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";
import DemandModal from "../../components/DemandModal";

export default function Demands() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "demands",
      breadcrumbName: "Encomendas",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome do Plugin",
      dataIndex: "pluginName",
      key: "pluginName",
    },
    {
      title: "Discord do Cliente",
      dataIndex: "discord",
      key: "discord",
    },
    {
      title: "Data do pedido",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Detalhes",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return <DemandModal data={record} onRemove={getData} />;
      },
    },
  ];

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/demand");

      setData(
        result.data.map((e) => {
          return {
            key: e.id,
            ...e,
          };
        })
      );
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar as encomendas");
    }

    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <AppLayout page="12">
      <PageHeader
        className="site-page-header"
        title="Encomendas"
        breadcrumb={{ routes }}
        subTitle="Gerencie as encomendas"
      />
      <Card title="Demandas" className="mt-4" bordered={false}>
        <Table
          dataSource={data}
          columns={columns}
          locale={{ emptyText: "Não há encomendas" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
