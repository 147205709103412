/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { PageHeader, Card, Table, message, Button } from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";
import ImageModel from "../../components/ImageModel";

export default function Partners() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataApproveds, setDataApproveds] = useState([]);


  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "partners",
      breadcrumbName: "Parceiros",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome do Servidor",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "IP do servidor",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Data do pedido",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Imagem",
      dataIndex: "image",
      key: "image",
      render: (text) => {
        return <ImageModel image={`https://api.atlasplugins.com/v1/file/image/${text}`} />
      },
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <>
            <Button type="primary" className="mr-2" onClick={() => setStatus(record.id, 'APPROVED')}>Aprovar</Button>
            <Button type="danger" onClick={() => setStatus(record.id, 'SUSPENDED')}>Reprovar</Button>
          </>
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome do Servidor",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "IP do servidor",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Data do pedido",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Imagem",
      dataIndex: "image",
      key: "image",
      render: (text) => {
        return <ImageModel image={`https://api.atlasplugins.com/v1/file/image/${text}`} />
      },
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <>
            <Button type="danger" onClick={() => setStatus(record.id, 'SUSPENDED')}>Suspender</Button>
          </>
        );
      },
    },
  ];

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/user/server/requests");
      const result2 = await api.get("/user/server/approveds");

      setData(
        result.data.map((e) => {
          return {
            key: e.id,
            ...e,
          };
        })
      );

      setDataApproveds(
        result2.data.map((e) => {
          return {
            key: e.id,
            ...e,
          };
        })
      );
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar as encomendas");
    }

    setLoading(false);
  }

  async function setStatus(id, status) {
    try {
      await api.patch(`/user/server/requests/${id}`, { status })
      await getData()
    } catch (err) {
      message.error('Não foi possível modificar o status da soliticação')
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <AppLayout page="11">
      <PageHeader
        className="site-page-header"
        title="Parceiros"
        breadcrumb={{ routes }}
        subTitle="Solicitações para aprovação"
      />
      <Card title="Solicitações" className="mt-4" bordered={false}>
        <Table
          dataSource={data}
          columns={columns}
          locale={{ emptyText: "Não há soliticações para parceiros" }}
          loading={loading}
        />
      </Card>
      <Card title="Parceiros aprovados" className="mt-4" bordered={false}>
        <Table
          dataSource={dataApproveds}
          columns={columns2}
          locale={{ emptyText: "Não há parceiros aprovados" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
