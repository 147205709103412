import React from "react";

import { Tag } from "antd";

function PriorityTag({ priority }) {
  var color =
    priority === "LOW" ? "success" : priority === "MEDIUM" ? "warning" : "red";
  var formatted =
    priority === "LOW" ? "Baixa" : priority === "MEDIUM" ? "Média" : "Alta";

  return <Tag color={color}>{formatted}</Tag>;
}

export default PriorityTag;
