import React, { useState, useEffect } from "react";

import { Table, Button, PageHeader, Card, message } from "antd";

import api from "../../apiv2";

import AppLayout from "../../layouts/AppLayout";

export default function ErrorReporting() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "errors",
      breadcrumbName: "Erros reportados",
    },
  ];

  const columns = [
    {
      title: "Plugin",
      dataIndex: "plugin",
      key: "plugin",
      render: (text, record) => record.plugin.title,
    },
    {
      title: "Stacktrace principal",
      dataIndex: "exceptionName",
      key: "exceptionName",
    },
    {
      title: "Versão do plugin",
      dataIndex: "pluginVersion",
      key: "pluginVersion",
    },
    {
      title: "Número de ocorrências",
      dataIndex: "numberOfReports",
      key: "numberOfReports",
    },

    {
      title: "Ações",
      dataIndex: "details",
      key: "details",
      render: (text, keys) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            type="primary"
            onClick={() =>
              window.open(
                `https://admin.atlasplugins.com/#/errors/${keys.id}`,
                "_self"
              )
            }
          >
            Mostrar mais
          </Button>

          <Button
            type="danger"
            className="ml-3"
            onClick={() => {
              console.log(keys);
              remove(keys.plugin.id, keys.exceptionName);
            }}
          >
            Excluir
          </Button>
        </div>
      ),
    },
  ];

  async function remove(id, stacktrace) {
    setLoading(true);

    try {
      await api.delete("/report/plugin/" + id, { data: { stacktrace } });

      await getData();
    } catch (err) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        message.error(err.response.data.message);
      }
    }

    setLoading(false);
  }

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/report");
      console.log(result);
      setData(
        result.data.map((el) => {
          return {
            key: el.id,
            ...el,
          };
        })
      );
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar os erros reportados.");
    }

    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <AppLayout page="15">
      <PageHeader
        className="site-page-header"
        breadcrumb={{ routes }}
        title="Erros reportados"
        subTitle="Lista de erros reportados automáticamente pelo AtlasLicense"
      />
      <Card title="Lista de erros" className="mt-4" bordered={false}>
        <Table
          dataSource={data}
          columns={columns}
          locale={{ emptyText: "Não há erros reportados pelo AtlasLicense :)" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
