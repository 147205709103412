/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { PageHeader, Card, Table, message, Button, Row, Col } from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../apiv2";

export default function ErrorReportingDetails({ history, match }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "errors",
      breadcrumbName: "Erros reportados",
    },
    {
      path: "errors",
      breadcrumbName: "Mais informações",
    },
  ];

  const columns = [
    {
      title: "Usuário",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record.user.username,
    },
    {
      title: "Stacktrace principal",
      dataIndex: "exceptionName",
      key: "exceptionName",
    },
    {
      title: "Versão do plugin",
      dataIndex: "pluginVersion",
      key: "pluginVersion",
    },
    {
      title: "Data do recebimento",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return <span>{new Date(text).toLocaleString("pt-br")}</span>;
      },
    },

    {
      title: "Ações",
      dataIndex: "details",
      key: "details",
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button type="primary" onClick={() => downloadLog(record.id)}>
            Baixar stacktrace completa
          </Button>

          <Button
            type="danger"
            className="ml-3"
            onClick={() => remove(record.id)}
          >
            Excluir
          </Button>
        </div>
      ),
    },
  ];

  async function remove(id) {
    setLoading(true);

    try {
      await api.delete("/report/" + id);

      await getData();
      message.success("Erro deletado com sucesso.");
    } catch (err) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        message.error(err.response.data.message);
      }
    }

    setLoading(false);
  }

  async function downloadLog(id) {
    try {
      api
        .get("/report/download/" + id, { responseType: "arraybuffer" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "error-report.txt"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log(error));
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar as informações do erro");
    }
  }

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/report/" + match.params.id);

      console.log(result.data);

      setData(result.data);
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar as informações do erro");
    }

    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [match]);

  return (
    <AppLayout page="15">
      <PageHeader
        className="site-page-header"
        title="Detalhes do erro"
        breadcrumb={{ routes }}
        subTitle="Mais informações sobre o erro"
      />
      <Card
        title="Informações sobre o erro"
        className="mt-4"
        loading={loading}
        bordered={false}
      >
        <Row>
          <Col md={12}>
            <p>
              <b>Stacktrace:</b> {data.stacktrace} <br />
              <b>Plugin:</b> {data.pluginName} <br />
              <b>Versão:</b> {data.pluginVersion} <br />
              <b>Usuários com problemas:</b>{" "}
              {data && data.errorUsers && data.errorUsers.length} <br />
            </p>
          </Col>
        </Row>
      </Card>
      <Card
        title="Lista de clientes com problemas"
        className="mt-4"
        bordered={false}
      >
        <Table
          dataSource={data.errorUsers}
          columns={columns}
          locale={{ emptyText: "Não houve licenças nessa transação" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
