import styled from "styled-components";

export const Card = styled.div`
  width: 450px;
  padding: 45px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 70px 45px -40px rgba(0, 0, 0, 0.1),
    0 10px 30px -5px rgba(0, 0, 0, 0.175);

  h1 {
    font-size: 28px;
    margin: 0;
  }

  h5 {
    font-weight: 300;
    color: #616161;
    margin-bottom: 35px;
  }

  input {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.05);
    border: none;
    margin-bottom: 10px;

    &:focus {
      outline: none;
    }
  }

  button {
    background-color: #7d5fff;
    color: #fff;
    padding: 15px 25px;
    border-radius: 5px;
    margin-top: 25px;
    transition: all ease 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
`;
