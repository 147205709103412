/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  PageHeader,
  Card,
  Tabs,
  Row,
  Col,
  Tag,
  Table,
  Select,
  Button,
  message,
} from "antd";

import { FaBan } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { TiUser } from "react-icons/ti";
import { SiDiscord } from "react-icons/si";
import { BsFillAwardFill } from "react-icons/bs";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

function ClientsDetails({ match, history }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    licenses: [],
    tickets: [],
    invoices: [],
  });
  const [plugins, setPlugins] = useState([]);
  const [pluginId, setPluginId] = useState(null);

  const { TabPane } = Tabs;

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "clients",
      breadcrumbName: "Clientes",
    },
  ];

  const licenseColumns = [
    {
      title: "Plugin",
      dataIndex: "pluginName",
      key: "pluginName",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Porta",
      dataIndex: "port",
      key: "port",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <Select
            defaultValue={text}
            onChange={(e) => changeStatus(record.id, e)}
          >
            <Select.Option value="PENDING">Aguardando pagamento</Select.Option>
            <Select.Option value="INTEGRATED">Integrado</Select.Option>
            <Select.Option value="WAITING">Aguardando integração</Select.Option>
            <Select.OptGroup label="Cuidado! É irreversível">
              <Select.Option value="DELETE" className="text-danger">
                Remover
              </Select.Option>
            </Select.OptGroup>
          </Select>
        );
      },
    },
  ];

  const ticketColumns = [
    {
      title: "Título",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Prioridade",
      dataIndex: "priority",
      key: "priority",
      render: (text) => {
        var color =
          text === "LOW" ? "success" : text === "MEDIUM" ? "warning" : "red";
        var formatted =
          text === "LOW" ? "Baixa" : text === "MEDIUM" ? "Média" : "Alta";

        return <Tag color={color}>{formatted}</Tag>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text === "OPENED"
          ? "NOVO"
          : text === "WAITING"
          ? "Aguardando resposta"
          : "Fechado";
      },
    },
    {
      title: "Última atualização",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => {
        return <span>{new Date(text).toLocaleString("pt-br")}</span>;
      },
    },
    {
      title: "Abrir",
      dataIndex: "actions",
      key: "actions",
      render: (text, keys) => {
        return (
          <span>
            <Button
              type="primary"
              onClick={() => history.push(`/tickets/${keys.id}`)}
            >
              Abrir
            </Button>
          </span>
        );
      },
    },
  ];

  const invoiceColumns = [
    {
      title: "Forma de Pagamento",
      dataIndex: "gateway",
      key: "gateway",
    },
    {
      title: "Moeda",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      render: (text) =>
        "R$" +
        parseFloat(text).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: "Desconto",
      dataIndex: "discount",
      key: "discount",
      render: (text, record) =>
        text
          ? `R$${parseFloat(text).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} (${record.cupom})`
          : "Não teve desconto",
    },
    {
      title: "Moeda",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text === "PENDING"
          ? "Aguardando pagamento"
          : text === "PAID"
          ? "Pago"
          : "Devolvido";
      },
    },
    {
      title: "Última atualização",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => {
        return <span>{new Date(text).toLocaleString("pt-br")}</span>;
      },
    },
  ];

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get(`/user/byId/${match.params.id}`);

      setData(result.data);
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar os usuários");
    }

    setLoading(false);
  }

  async function getPlugins() {
    setLoading(true);

    try {
      const result = await api.get("/category/PAID/type");

      setPlugins(result.data);
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar os plugins");
    }

    setLoading(false);
  }

  async function addLicense() {
    if (pluginId === null) {
      return message.info("Selecione um plugin");
    }

    try {
      await api.post(`/license/${match.params.id}`, {
        pluginId,
      });

      setPluginId(null);
      getData();
    } catch (err) {
      message.error(
        "Ocorreu um erro ao tentar adicionar licença para o usuário"
      );
    }
  }

  async function banUser() {
    try {
      await api.patch(`/user/${match.params.id}/ban`);

      getData();
      message.success("Usuário banido com sucesso.");
    } catch (err) {
      message.error("Ocorreu algum erro ao processar sua solicitação");
    }
  }

  async function unBanUser() {
    try {
      await api.patch(`/user/${match.params.id}/unban`);

      getData();
      message.success("Usuário desbanido com sucesso.");
    } catch (err) {
      message.error("Ocorreu algum erro ao processar sua solicitação");
    }
  }

  async function upgrade() {
    try {
      await api.patch(`/user/${match.params.id}/support`);

      getData();
    } catch (err) {
      message.error("Ocorreu algum erro ao processar sua solicitação");
    }
  }

  async function changeStatus(id, status) {
    try {
      if (status === "DELETE") {
        await api.delete("/license/" + id);
        getData();
      } else {
        await api.patch("/license/" + id, { status });
        message.success("Atualizado!");
      }

      getData();
    } catch (err) {
      message.error("Ocorreu um erro ao tentar atualizar o status");
    }
  }

  useEffect(() => {
    getData();
    getPlugins();
  }, []);

  return (
    <AppLayout page="2">
      <PageHeader
        className="site-page-header"
        title="Clientes"
        breadcrumb={{ routes }}
        subTitle="Gerencie os clientes"
      />
      <Card
        title="Detalhes do cliente"
        className="mt-4"
        bordered={false}
        loading={loading}
      >
        <Row gutter={16}>
          <Col span={24} md={6}>
            <h3>Cliente #{match.params.id}</h3>
            <h6>
              <b>
                <TiUser /> Usuário:
              </b>{" "}
              {data.username} <br />
              <b>
                <MdEmail /> E-mail:
              </b>{" "}
              {data.email} <br />
              <b>
                <SiDiscord /> Discord:
              </b>{" "}
              {data.discord ? data.discord : "Não definido"} <br />
              <b>
                <BsFillAwardFill /> Nível de permissão:
              </b>{" "}
              {data.level === "CUSTOMER"
                ? "Cliente"
                : data.level === "ADMINISTRATOR"
                ? "Admin"
                : data.level === "SUPPORT"
                ? "Suporte"
                : "Suspenso"}
              <br />
              <b>
                <FaBan /> Banido:
              </b>{" "}
              {data.banned ? "SIM" : "NÃO"}
              <br />
              <br />
              {data.level === "CUSTOMER" && (
                <>
                  <Button block onClick={upgrade}>
                    Tornar assistente de suporte
                  </Button>
                  <br />
                  <br />
                  {!data.banned ? (
                    <Button block onClick={banUser} type="danger">
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FaBan style={{ marginRight: "4px" }} /> Banir
                      </p>
                    </Button>
                  ) : (
                    <Button block onClick={unBanUser} type="danger">
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FaBan style={{ marginRight: "4px" }} /> Desbanir
                      </p>
                    </Button>
                  )}
                </>
              )}
            </h6>
          </Col>
          <Col span={24} md={18}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Licenças" key="1">
                <Row gutter={16}>
                  <Col span={24} md={8}>
                    <h5>Adicionar licença</h5>
                    <Select
                      placeholder="Selecione o plugin"
                      className="w-100"
                      onChange={setPluginId}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {plugins.map((category) =>
                        category.plugins.map((el) => (
                          <Select.Option key={`pl-${el.id}`} value={el.id}>
                            {el.title}
                          </Select.Option>
                        ))
                      )}
                    </Select>
                    <br />
                    <br />
                    <Button block type="primary" onClick={addLicense}>
                      Adicionar
                    </Button>
                  </Col>
                  <Col span={24} md={16}>
                    <Table
                      dataSource={data.licenses.map((e) => {
                        return {
                          key: e.id,
                          ...e,
                        };
                      })}
                      columns={licenseColumns}
                      locale={{
                        emptyText: "Não há licenças ativas para este usuário",
                      }}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Tickets" key="2">
                <Table
                  dataSource={data.tickets.map((ticket) => {
                    return {
                      key: ticket.id,
                      ...ticket,
                    };
                  })}
                  columns={ticketColumns}
                  locale={{ emptyText: "Não há tickets abertos" }}
                  loading={loading}
                />
              </TabPane>
              <TabPane tab="Faturas" key="3">
                <Table
                  dataSource={data.invoices.map((invoice) => {
                    return {
                      key: invoice.id,
                      ...invoice,
                    };
                  })}
                  columns={invoiceColumns}
                  locale={{ emptyText: "Não há faturas abertas" }}
                  loading={loading}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Card>
    </AppLayout>
  );
}

export default ClientsDetails;
