import React, { useState } from "react";
import { Button, Modal } from "antd";

function ImageModel({ image }) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Modal
        title="Visualizar imagem"
        visible={visible}
        okText="Ok"
        cancelText="Cancelar"
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      >
        <img src={image} className="img-fluid" alt="Atlas Plugins - Imagem" />
      </Modal>
      <Button onClick={() => setVisible(true)}>Ver imagem</Button>
    </>
  );
}

export default ImageModel;
