import React, { useState, useEffect } from "react";
import { PageHeader, Card, Input, Table, Tag, message, Row, Col, Button } from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../api";

export default function Transactions({ history }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("")

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "transactions",
      breadcrumbName: "Transações",
    },
  ];
  
  const columns = [
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Usuário",
      dataIndex: "username",
      key: "username",
      render: (text, record) => record.invoice.user.username
    },
    {
      title: "Nome do Pagador",
      dataIndex: "payerName",
      key: "payerName",
    },
    {
      title: "E-mail",
      dataIndex: "payerEmail",
      key: "payerEmail",
    },
    {
      title: "Valor",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
    },
    {
      title: "Forma de Pagamento",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Última atualização",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => new Date(text).toLocaleString('pt-BR')
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text) => {
        return text === 'PENDING' ? (
          <Tag color="geekblue">Pagamento Pendente</Tag>
        ) : text === 'PAID' ? (
          <Tag color="green">Pago</Tag>
        ) : (
          <Tag color="red">Reembolsado</Tag>
        );
      },
    },
    {
      title: "Detalhes",
      dataIndex: "details",
      key: "details",
      render: (text, record) => <Button onClick={() => history.push('/transactions/' + record.id)}>Detalhes</Button>,
    }
  ];

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/transaction");

      setData(result.data.map(el => {
        return {
          key: el.id,
          ...el
        }
      }));
    } catch (err) {
      message.error("Ocorreu um erro ao tentar buscar as transações");
    }

    setLoading(false);
  }

  function filterData() {
    return data.filter(res => {
      return (JSON.stringify(res).toLocaleLowerCase()).match(search.toLocaleLowerCase());
    });
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AppLayout page="10">
      <PageHeader
        className="site-page-header"
        title="Transações"
        breadcrumb={{ routes }}
        subTitle="Histórico de Transações"
      />
      <Card title="Transações" className="mt-4" bordered={false}>
        <Row gutter={16}>
          <Col span={24} md={20}>
            <Input block placeholder="Pesquisar..." value={search} onChange={e => setSearch(e.target.value)} size="large" />
          </Col>
          <Col span={24} md={4}>
            <Button size="large" block onClick={() => setSearch('"status":"PAID"')}>Mostrar Pagos</Button>
          </Col>
        </Row>
        <br /><br />
        <Table
          dataSource={search.length > 0 ? filterData() : data}
          columns={columns}
          locale={{ emptyText: "Não há transações por aqui" }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  );
}
